<template>
  <v-container class="pa-6">
    <v-row row>
      <v-col cols="12" class="d-flex align-end">
        <h2 class="display-1">{{L('Database The Finnish National Gallery')}}</h2>
        <p class="title mb-0 ml-3"><a href="https://www.kansallisgalleria.fi/" target="_blank">The Finnish National Gallery</a></p>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <h3>{{L('Filters')}}:</h3>
        <v-chip class="mr-2" v-for="(filter, i) in filters" :key="`filter_${i}`" close @click="editFilter(filter)" @click:close="removeFilter(i)">{{filter.text}}: {{filter.filter}}</v-chip>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select outlined dense hide-details v-model="selectedProp" return-object :label="L('Column')" :items="columnOptions" @change="qSearch = '';"></v-select>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedProp">
        <v-select v-if="selectedProp.type === 'bool'" filled rounded dense hide-details v-model="qSearch" :label="L('Filter')" :items="boolOptions" ></v-select>
        <v-text-field v-else :type="selectedProp.type === 'int' || selectedProp.type === 'number' ? 'number' : 'text'" :step="selectedProp.type === 'int'  ? 1 : 0.000001 " outlined dense hide-details v-model="qSearch" :label="L('Filter')"></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedProp && qSearch !== null">
        <v-btn color="accent" @click="setFilter()">Imposta il filtro</v-btn>
      </v-col>

      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="columnsDialog = true">Colonne da visualizzare</v-btn>
        <v-switch hide-details v-model="query.hide_ignored" label="Nascondi ignorati" class="flex-grow-0 flex-shrink-1 mt-0 ml-3" @change="applyFilter"/>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="selectAll()">Seleziona tutto</v-btn>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="ignoreSelected()">Ignora selezionati</v-btn>
        <v-autocomplete outlined dense class="flex-grow-0 flex-shrink-1 ml-2" :label="L('Collection of import')" hide-details v-model="collectionId" :items="collectionOptions" clearable></v-autocomplete>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="enqueueSelected()" :disabled="!collectionId">Importa selezionati</v-btn>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                      no-data-text="non ci sono record al momento" :footer-props="footerProps" >

          <template v-slot:item.select="{ item }">
            <v-checkbox v-model="selectedItems[item._id]" :key="item._id + 'check' + refreshKey"></v-checkbox>
          </template>

          <template v-slot:item.title="{ item }">
            <p class="mb-0">en: {{item.title.en}}</p>
            <p class="mb-0">fi: {{item.title.fi}}</p>
            <p class="mb-0">sv: {{item.title.sv}}</p>
          </template>

          <template v-slot:item.thumbnail="{ item }">
            <v-img :src="item.multimedia[0].jpg.dim250" contain class="black" max-width="80" max-height="80"></v-img>
          </template>

          <template v-slot:item.people.familyName="{ item }">
            <p class="mb-0" v-for="p in item.people">{{ p.firstName ? p.firstName + ' ' + p.familyName : p.familyName }}</p>
          </template>

          <template v-slot:item.view="{ item }">
            <v-btn icon @click="viewItem = item; viewDialog = true" color="accent"><v-icon>mdi-file-find</v-icon></v-btn>
          </template>

          <template v-slot:item.ignore="{ item }">
            <v-btn icon @click="toggleIgnore(item)"><v-icon v-if="item.ignore" color="error">mdi-eye-off</v-icon><v-icon v-else>mdi-eye-off</v-icon></v-btn>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="columnsDialog" max-width="800">
      <v-card>
        <v-card-title>
          Seleziona le colonne da visualizzare
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="p in columnOptions" :key="p.value">
              <v-checkbox hide-details v-model="selectedColumns[p.value]" :label="p.text" @change="persistColumns()"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="accent" @click="columnsDialog = false">Chiudi</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-dialog v-model="viewDialog" max-width="800">
      <v-card>
        <v-card-text v-if="viewItem">
          <v-row class="pt-6">
            <v-col cols="12" v-if="viewItem.image_thumbnail"><v-img :max-width="800" :max-height="300" contain :src="viewItem.image_thumbnail"></v-img></v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object ID</strong>: {{viewItem.objectId}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Responsible Organisation</strong>: {{viewItem.responsibleOrganisation}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Dimensioni:</strong>: {{viewItem.dimensions.join(' x ')}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Unità di misura</strong>: {{viewItem.dimensionUnit}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Titolo</strong>: {{viewItem.title}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Materiali</strong>: {{joinLangDicts(viewItem.materials)}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Categoria</strong>: {{viewItem.category.categoryId}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Classificazioni</strong>: {{joinLangDicts(viewItem.classifications)}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Num inventario</strong>: {{viewItem.inventoryNumber}}
            </v-col>
            <v-col v-for="(art, i) in viewItem.people" :key="`people_${i}_${viewItem._id}`">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artista #{{i+1}}</strong>:
              <ul>
                <li>Nome: {{art.firstName || '-'}}</li>
                <li>Cognome: {{art.familyName}}</li>
                <li>Data nascita: {{art.birthDate || '-'}}</li>
                <li>Data morte: {{art.deathDate || '-'}}</li>
                <li>Luogo nascita: {{art.birthPlace || '-'}}</li>
                <li>Luogo morte: {{art.deathPlace || '-'}}</li>
              </ul>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Collezione</strong>: {{viewItem.collection}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Anno da</strong>: {{viewItem.yearFrom}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Anno a</strong>: {{viewItem.yearTo}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Anno acquisizione</strong>: {{viewItem.acquisitionYear}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Opera ignorata</strong>: <span v-if="viewItem.ignored" class="error font-weight-bold">Sì</span><span v-else>No</span>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0" v-for="(multi, i) in viewItem.multimedia" :key="`jpg_${i}_${viewItem._id}`">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Url immagine jpg #{{i+1}} <span v-if="multi.isRiaDisplayImage">(principale)</span></strong>: <a :href="multi.jpg.dim4000" target="_blank">{{multi.jpg.dim4000}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0" v-for="(multi, i) in viewItem.multimedia" :key="`webp_${i}_${viewItem._id}`">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Url immagine webp #{{i+1}} <span v-if="multi.isRiaDisplayImage">(principale)</span></strong>: <a :href="multi.webp.dim4000" target="_blank">{{multi.webp.dim4000}}</a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="accent" @click="viewDialog = false; viewItem = null">Chiudi</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
export default {
  data () {
    return {
      collectionId : null,
      refreshKey : 0,
      viewDialog : false,
      viewItem : null,
      setImagesDialog : false,
      images : {},
      columnsDialog: false,
      refreshTimeout : -1,
      filters : [],
      selectedItems : {},
      selectedProp : null,
      selectedColumns : {
        thumbnail : true,
        title : true,
        'people.familyName' : true,
        collection : true,
        yearFrom : true,
        yearTo : true,
      },
      hideIgnored : true,
      qSearch : null,
      collectionSearch : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : this.L('No. rows per page'),
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      columnOptions : [
        { text : 'Object ID', value : 'objectId', type : 'string', sortable : true},
        { text : 'Responsible Organisation', value : 'responsibleOrganisation', type : 'string', sortable : true},
        { text : 'Thumbnail', value : 'thumbnail', type : 'string', sortable : false},
        { text : 'Dimensioni', value : 'dimensions', type : 'int', sortable : true},
        { text : 'Unità di misura', value : 'dimensionUnit', type : 'string', sortable : true},
        { text : 'Titolo', value : 'title', type : 'string', sortable : true},
        { text : 'Materiale', value : 'materials.en', type : 'string', sortable : false},
        { text : 'Categoria', value : 'category.categoryId', type : 'string', sortable : true},
        { text : 'Classificazione', value : 'classifications.en', type : 'string', sortable : true},
        { text : 'Num inventario', value : 'inventoryNumber', type : 'string', sortable : true},
        { text : 'Artista', value : 'people.familyName', type : 'string', sortable : false},
        { text : 'Collezione', value : 'collection', type : 'string', sortable : true},
        { text : 'Anno da', value : 'yearFrom', type : 'int', sortable : true},
        { text : 'Anno a', value : 'yearTo', type : 'int', sortable : true},
        { text : 'Anno acquisizione', value : 'acquisitionYear', type : 'int', sortable : true},
        { text : 'Ignora', value : 'ignore', type : 'bool', sortable : true},
        { text : 'ArtCentrica ID', value : 'artc_id', type : 'string', sortable : true},
        { text : 'ArtCentrica Imgf ID', value : 'artc_img_id', type : 'string', sortable : true},
        { text : 'ArtCentrica Tecnica ID', value : 'artc_tec_id', type : 'string', sortable : true},
      ],
      count : 0,
      items : [],
      pagination: {
        sortBy : [ "title" ],
        sortDesc : [ false ]
      },
      query : {
        hide_ignored : true,
      },
      boolOptions : [{text: 'Sì', value:true},{text: 'No', value:false}]
    }
  },
  watch: {
    pagination: {
      handler () {
        this.selectedItems = {}
        sessionStorage.finnishPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    },
  },
  computed : {
    ...mapGetters(['profile','collections','languageMap']),
    collectionOptions() {
      return this.collections.map(item => {
        let text = item.title.it|| item.title.en
        return { text, value : item._id }
      })
    },
    headers(){
      let cols = this.columnOptions.filter(item => this.selectedColumns[item.value]).map(item => {
        return { ...item, sortable: true, align: 'left'}
      })
      return [
        { text : "Selezione", sortable: false, align: 'center', value : 'select', width : 50 },
          ...cols,
        { text : "Vedi", sortable: false, align: 'center', value : 'view', width : 50 },
        { text : "Ignora", sortable: false, align: 'center', value : 'ignore', width : 50 },
      ]
    },
    server() {
      return config.server;
    },

  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'insert', 'get', 'delete','refreshMainCollection']),
    L( key ) {
      return this.languageMap && this.languageMap[key] ? this.languageMap[key] : key;
    },
    joinLangDicts( dicts ) {
      return dicts.map(item => item.en || item.fi).join("; ")
    },
    selectAll() {
      let pairs = _.toPairs(this.selectedItems);
      if(pairs.length < this.items.length) {
        for(let i = 0; i < this.items.length; i++) {
          this.selectedItems[this.items[i]._id] = true;
        }
      } else {
        this.selectedItems = {}
      }
      this.refreshKey++;
    },
    async enqueueSelected() {
      if(!this.collectionId) return;
      let pairs = _.toPairs(this.selectedItems);
      if(!pairs.length) return

      this.tableLoading = true
      let ids = [];
      for(let id in this.selectedItems) {
        ids.push(id)
      }

      try {
        let reply = await this.insert({collection : 'finnish/enqueue', data : { collection_id : this.collectionId, ids }})
        if(reply.errors && reply.errors.length) {
          this.sendError({message:"Ci sono stati degli errori in alcune importazioni, vedi console del browser per maggiori dettagli"})
          console.log(['------------- ERRORI DI IMPORT --------------', ...reply.errors,'---------------------------------------'].join("\n"))
        } else {
          this.sendSuccess({message:"Record inseriti nella coda di importazione!"})
        }
        this.tableLoading = false
        this.refresh()
      } catch (e) {
        this.tableLoading = false
        this.sendError({message:"C'è stato un errore del server"})
        console.log(e)
      }

    },

    async toggleIgnore(item, noRefresh) {
      try {
        let id = item._id + ( item.ignore ? "/show" : "/hide" );
        let { data } = await this.get({collection:'finnish', id})
        this.sendSuccess({message:"Operazione riuscita!"})
        if(!noRefresh) this.refresh()
        return data
      } catch (e) {
        this.sendError({message:"Errore del server: " + e.message})
      }
      return null
    },
    async ignoreSelected() {
      this.tableLoading = true
      for(let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if(this.selectedItems[item._id] && !item.ignore) {
          let ignored = await this.toggleIgnore(item)
          if (ignored) {
            this.items[i] = ignored;
            this.selectedItems[item._id] = false;
          }
        }
      }
      this.tableLoading = false
    },
    persistColumns() {
      localStorage.finnishColumns = JSON.stringify(this.selectedColumns);
    },
    editFilter(item) {
      let { text, value, type, filter} = item
      this.selectedProp = { text, value, type };
      this.qSearch = filter;
    },
    removeFilter(i) {
      this.filters.splice(i, 1)
      this.applyFilter();
    },
    setFilter() {
      if(!this.selectedProp && !this.qSearch) return
      let filter = {...this.selectedProp, filter: this.qSearch}

      for(let i = 0; i < this.filters.length; i++) {
        if(this.filters[i].value === filter.value) {
          this.filters[i] = filter
          this.applyFilter();
          return;
        }
      }
      this.filters.push(filter)
      this.applyFilter();
    },
    applyFilter() {
      this.selectedProp = null
      this.qSearch = null;
      this.query.filters = utils.copy(this.filters)
      this.pagination.page = 1;
      sessionStorage.finnishQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      if(sortBy && sortBy.length) {
        this.query.sort = sortBy[0]
        this.query.sort_direction = sortDesc && sortDesc[0] ? -1 : 1
      }

      this.query.limit = itemsPerPage;
      this.query.page = page - 1;

      this.insert({ collection : 'finnish', data : this.query}).then((reply)=>{
        this.tableLoading = false;
        if(reply.success || reply.status === 'success') {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento del db finnish."});
        if(config.debug) console.error("Errore al caricamento del db finnish: ", err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.finnishQuery) {
      this.query = JSON.parse(sessionStorage.finnishQuery);
      this.filters = utils.copy(this.query.filters)
    }
    if(sessionStorage.finnishPagination) {
      this.pagination = JSON.parse(sessionStorage.finnishPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
    this.refreshMainCollection({collection:'collections'});
    if(localStorage.finnishColumns) {
      this.selectedColumns = JSON.parse(localStorage.finnishColumns)
    }

  }
}
</script>
