<template>
  <v-container class="pa-6">
    <v-row row>
      <v-col cols="12" class="d-flex">
        <h2 class="display-1">Database OAI</h2>
        <v-spacer></v-spacer>
        <v-btn text color="accent" @click="$router.push('/oai/new')">Nuovo</v-btn>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field outlined dense hide-details v-model="query.name" return-object label="Cerca per nome" @input="applyFilter()"></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                      :no-data-text="L('There are no records yet')" :footer-props="footerProps" >


          <template v-slot:item.colletion_id="{ item }">
            <span>{{getCollectionName(item.collection_id)}}</span>
          </template>


          <template v-slot:item.download_done="{ item }">
            <span v-if="item.download_done" class="font-weight-bold success--text">Sì</span>
            <span v-else class="font-weight-bold error--text">No</span>
          </template>

          <template v-slot:item.download="{ item }">
            <v-btn icon @click="downloadOaiCollection(item._id)" color="accent"><v-icon>mdi-cloud-download</v-icon></v-btn>
          </template>

          <template v-slot:item.sync="{ item }">
            <v-btn :disabled="!item.download_done" icon @click="syncOaiCollection(item._id)" color="accent"><v-icon>mdi-database-sync</v-icon></v-btn>
          </template>

          <template v-slot:item.edit="{ item }">
            <v-btn icon @click="$router.push(`/oai/${item._id}`)" color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>

          <template v-slot:item.delete="{ item }">
            <v-btn icon @click="requestDelete(item)"><v-icon color="error">mdi-close</v-icon></v-btn>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
export default {
  data () {
    return {
      filterName : null,
      refreshKey : 0,
      refreshTimeout : -1,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : this.L('No. rows per page'),
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      count : 0,
      items : [],
      pagination: {
        sortBy : [ "name" ],
        sortDesc : [ false ]
      },
      query : {
        name : ''
      },
    }
  },
  watch: {
    pagination: {
      handler () {
        this.selectedItems = {}
        sessionStorage.oaiPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    },
  },
  computed : {
    ...mapGetters(['profile','collections','languageMap']),
    collectionOptions() {
      return this.collections.map(item => {
        let text = item.title.it|| item.title.en
        return { text, value : item._id }
      })
    },
    headers(){
      return [
        { text : this.L('Name'), sortable: true, align: 'center', value : 'name' },
        { text : this.L('Collection'), sortable: false, align: 'center', value : 'collection_id'},
        { text : this.L('Db Collection'), sortable: true, align: 'center', value : 'db_collection'},
        { text : this.L('OAI endpoint'), sortable: false, align: 'center', value : 'oai_endpoint'},
        { text : this.L('OAI format'), sortable: true, align: 'center', value : 'oai_format'},
        { text : this.L('Downloaded'), sortable: true, align: 'center', value : 'download_done', width : 50 },
        { text : "Scarica", sortable: false, align: 'center', value : 'download', width : 50 },
        { text : "Sincronizza col db", sortable: false, align: 'center', value : 'sync', width : 50 },
        { text : this.L('Edit'), sortable: false, align: 'center', value : 'edit', width : 50 },
        { text : this.L('Delete'), sortable: false, align: 'center', value : 'delete', width : 50 },
      ]
    },
    server() {
      return config.server;
    },

  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'insert', 'get', 'delete','refreshMainCollection']),
    L( key ) {
      return this.languageMap && this.languageMap[key] ? this.languageMap[key] : key;
    },

    getCollectionName(id) {
      let filter = this.collections.filter(item => item._id === id)
      return (filter.length) ? filter[0].title.it || filter[0].title.en : '-'
    },
    async downloadOaiCollection(id) {
      this.tableLoading = true
      await this.get({ collection : 'oai' , id: id+"/download" })
      this.tableLoading = false
    },

    async syncOaiCollection(id) {
      this.tableLoading = true
      await this.get({ collection : 'oai' , id: id+"/sync" })
      this.refresh()
    },

    applyFilter() {
      this.pagination.page = 1;
      sessionStorage.oaiQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      if (sortBy && sortBy.length) {
        sort = sortBy[0];
        if (sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }


      let filter = {
        query: this.query,
        limit: itemsPerPage,
        page,
        sort
      };

      this.load({ collection : 'oai', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.success || reply.status === 'success') {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento dei db oai."});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.oaiQuery) {
      this.query = JSON.parse(sessionStorage.oaiQuery);
    }
    if(sessionStorage.oaiPagination) {
      this.pagination = JSON.parse(sessionStorage.oaiPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
    this.refreshMainCollection({collection:'collections'});
  }
}
</script>
