const LEVEL = "prod";

const commons = {
    rijksmuseum_key : "NNBCKuHa",
    metProps : [
        { text : 'Object ID', value : 'object_id', type : 'int'},
        { text : 'Object Number', value : 'object_number', type : 'string'},
        { text : 'Is Highlight', value : 'is_highlight', type : 'bool'},
        { text : 'Is Timeline Work', value : 'is_timeline_work', type : 'bool'},
        { text : 'Is Public Domain', value : 'is_public_domain', type : 'bool'},
        { text : 'Gallery Number', value : 'gallery_number', type : 'string'},
        { text : 'Department', value : 'department', type : 'string'},
        { text : 'Accession Year', value : 'accession_year', type : 'string'},
        { text : 'Object Name', value : 'object_name', type : 'string'},
        { text : 'Title', value : 'title', type : 'string'},
        { text : 'Culture', value : 'culture', type : 'string'},
        { text : 'Period', value : 'period', type : 'string'},
        { text : 'Portfolio', value : 'portfolio', type : 'string'},
        { text : 'Constiuent ID', value : 'constiuent_id', type : 'string'},
        { text : 'Artist Role', value : 'artist_role', type : 'string'},
        { text : 'Artist Prefix', value : 'artist_prefix', type : 'string'},
        { text : 'Artist Suffix', value : 'artist_suffix', type : 'string'},
        { text : 'Artist Display Name', value : 'artist_display_name', type : 'string'},
        { text : 'Artist Display Bio', value : 'artist_display_bio', type : 'string'},
        { text : 'Artist Alpha Sort', value : 'artist_alpha_sort', type : 'string'},
        { text : 'Artist Nationality', value : 'artist_nationality', type : 'string'},
        { text : 'Artist Begin Date', value : 'artist_begin_date', type : 'string'},
        { text : 'Artist End Date', value : 'artist_end_date', type : 'string'},
        { text : 'Artist Gender', value : 'artist_gender', type : 'string'},
        { text : 'Artist ULAN URL', value : 'artist_ulan_url', type : 'string'},
        { text : 'Artist Wikidata URL', value : 'artist_wikidata_url', type : 'string'},
        { text : 'Object Date', value : 'object_date', type : 'string'},
        { text : 'Object Begin Date', value : 'object_begin_date', type : 'int'},
        { text : 'Object End Date', value : 'object_end_date', type : 'int'},
        { text : 'Medium', value : 'medium', type : 'string'},
        { text : 'Dimensions', value : 'dimensions', type : 'string'},
        { text : 'Credit Line', value : 'credit_line', type : 'string'},
        { text : 'Geography Type', value : 'geography_type', type : 'string'},
        { text : 'City', value : 'city', type : 'string'},
        { text : 'State', value : 'state', type : 'string'},
        { text : 'County', value : 'county', type : 'string'},
        { text : 'Country', value : 'country', type : 'string'},
        { text : 'Region', value : 'region', type : 'string'},
        { text : 'Subregion', value : 'subregion', type : 'string'},
        { text : 'Locale', value : 'locale', type : 'string'},
        { text : 'Locus', value : 'locus', type : 'string'},
        { text : 'Excavation', value : 'excavation', type : 'string'},
        { text : 'River', value : 'river', type : 'string'},
        { text : 'Classification', value : 'classification', type : 'string'},
        { text : 'Rights And Reproduction', value : 'rights_and_reproduction', type : 'string'},
        { text : 'Link Resource', value : 'link_resource', type : 'string'},
        { text : 'Object Wikidata URL', value : 'object_wikidata_url', type : 'string'},
        { text : 'Metadata Date', value : 'metadata_date', type : 'string'},
        { text : 'Repository', value : 'repository', type : 'string'},
        { text : 'Tags', value : 'tags', type : 'string'},
        { text : 'Tags AAT URL', value : 'tags_aat_url', type : 'string'},
        { text : 'Tags Wikidata URL', value : 'tags_wikidata_url', type : 'string'},
        { text : 'MD5', value : 'md5', type : 'string'},
        { text : 'Images urls', value : 'images_url', type : 'string'},
    ],
};

const dev = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    mainServer : 'https://api.devart.centrica.it',
    rijksmuseum_server : "http://localhost:8000/api/rijksmuseum/",
    server : 'http://localhost:8000',
    editorUrl : 'https://editor.devart.centrica.it/#/',
    adminUrl : 'https://admin.devart.centrica.it/#/',
    production : false,
};
const preprod = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    mainServer : 'https://api.devart.centrica.it',
    rijksmuseum_server : "https://oa.devart.centrica.it/api/rijksmuseum/",
    server : 'https://oa.devart.centrica.it',
    editorUrl : 'https://editor.devart.centrica.it/#/',
    adminUrl : 'https://admin.devart.centrica.it/#/',
    production : false,
};
const prod = {
    debug : false,
    logEnabled : true,
    logLevel : 'warning', // debug, info, warning, error
    mainServer : 'https://api.art.centrica.it',
    rijksmuseum_server : "https://oa.art.centrica.it/api/rijksmuseum/",
    server : 'https://oa.art.centrica.it',
    editorUrl : 'https://editor.art.centrica.it/#/',
    adminUrl : 'https://admin.art.centrica.it/#/',
    production : true,
};

let config;

switch (LEVEL) {
    case "prod":
        config = Object.assign(commons, prod);
        break;
    case "preprod":
        config = Object.assign(commons, preprod);
        break;
    case "dev":
    default:
        config = Object.assign(commons, dev);
        break;
}

export default config;