<template>
  <v-container class="pa-6">
    <v-row row>
      <v-col cols="12" class="d-flex align-end">
        <h2 class="display-1">{{L('Database Reims Paintings')}}</h2>
        <p class="title mb-0 ml-3"><a href="https://musees-reims.fr" target="_blank"> Musées de Reims</a></p>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <h3>{{L('Filters')}}:</h3>
        <v-chip class="mr-2" v-for="(filter, i) in filters" :key="`filter_${i}`" close @click="editFilter(filter)" @click:close="removeFilter(i)">{{filter.text}}: {{filter.filter}}</v-chip>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select outlined dense hide-details v-model="selectedProp" return-object :label="L('Column')" :items="columnOptions" @change="qSearch = '';"></v-select>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedProp">
        <v-select v-if="selectedProp.type === 'bool'" filled rounded dense hide-details v-model="qSearch" :label="L('Filter')" :items="boolOptions" ></v-select>
        <v-text-field v-else :type="selectedProp.type === 'int' || selectedProp.type === 'number' ? 'number' : 'text'" :step="selectedProp.type === 'int'  ? 1 : 0.000001 " outlined dense hide-details v-model="qSearch" :label="L('Filter')"></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedProp && qSearch !== null">
        <v-btn color="accent" @click="setFilter()">Imposta il filtro</v-btn>
      </v-col>

      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="columnsDialog = true">Colonne da visualizzare</v-btn>
        <v-switch hide-details v-model="query.hide_ignored" label="Nascondi ignorati" class="flex-grow-0 flex-shrink-1 mt-0 ml-3" @change="applyFilter"/>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="selectAll()">Seleziona tutto</v-btn>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="ignoreSelected()">Ignora selezionati</v-btn>
        <v-autocomplete outlined dense class="flex-grow-0 flex-shrink-1 ml-2" :label="L('Collection of import')" hide-details v-model="collectionId" :items="collectionOptions" clearable></v-autocomplete>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="enqueueSelected()" :disabled="!collectionId">Importa selezionati</v-btn>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                      no-data-text="non ci sono record al momento" :footer-props="footerProps" >

          <template v-slot:item.select="{ item }">
            <v-checkbox v-model="selectedItems[item._id]" :key="item._id + 'check' + refreshKey"></v-checkbox>
          </template>

          <template v-slot:item.ThumbnailURL="{ item }">
            <v-img :src="item.ThumbnailURL" contain class="black" max-width="80" max-height="80"></v-img>
          </template>

          <template v-slot:item.view="{ item }">
            <v-btn icon @click="viewItem = item; viewDialog = true" color="accent"><v-icon>mdi-file-find</v-icon></v-btn>
          </template>

          <template v-slot:item.ignore="{ item }">
            <v-btn icon @click="toggleIgnore(item)"><v-icon v-if="item.ignore" color="error">mdi-eye-off</v-icon><v-icon v-else>mdi-eye-off</v-icon></v-btn>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="columnsDialog" max-width="800">
      <v-card>
        <v-card-title>
          Seleziona le colonne da visualizzare
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="p in columnOptions" :key="p.value">
              <v-checkbox hide-details v-model="selectedColumns[p.value]" :label="p.text" @change="persistColumns()"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="accent" @click="columnsDialog = false">Chiudi</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-dialog v-model="viewDialog" max-width="800">
      <v-card>
        <v-card-text v-if="viewItem">
          <v-row class="pt-6">
            <v-col cols="12" v-if="viewItem.image_thumbnail"><v-img :max-width="800" :max-height="300" contain :src="viewItem.image_thumbnail"></v-img></v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Remote ID</strong>: {{viewItem.RemoteID}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Image Name</strong>: {{viewItem.ImageName}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artista:</strong>: {{viewItem.Artist}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Titolo</strong>: {{viewItem.Title}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>ImageDescription</strong>: {{viewItem.ImageDescription}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>ObjectDate</strong>: {{viewItem.ObjectDate}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>H x L cm</strong>: {{viewItem.MetadataBlob.info.cm_hw}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>H x L cm con cornice</strong>: {{viewItem.MetadataBlob.info.cm_hw_with_frame}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>H x L cm senza cornice</strong>: {{viewItem.MetadataBlob.info.cm_hw_no_frame}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>width cm</strong>: {{viewItem.MetadataBlob.info.width}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>height cm</strong>: {{viewItem.MetadataBlob.info.height}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>depth cm</strong>: {{viewItem.MetadataBlob.info.depth}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>width cm con cornice</strong>: {{viewItem.MetadataBlob.info.width_with_frame}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>height cm con cornice</strong>: {{viewItem.MetadataBlob.info.height_with_frame}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>depth cm con cornice</strong>: {{viewItem.MetadataBlob.info.depth_with_frame}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>N. inventario</strong>: {{viewItem.MetadataBlob.info.inv_number}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Data</strong>: {{viewItem.MetadataBlob.info.date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Evento</strong>: {{viewItem.MetadataBlob.info.event}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Luogo</strong>: {{viewItem.MetadataBlob.info.place}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Materiale</strong>: {{viewItem.MetadataBlob.material}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Parte n°</strong>: {{viewItem.MetadataBlob.num_of_parts}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Creation stage</strong>: {{viewItem.MetadataBlob.creation_stage}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Recipient</strong>: {{viewItem.MetadataBlob.recipient}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Data utilizzo</strong>: {{viewItem.MetadataBlob.date_of_use}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Luogo utilizzo</strong>: {{viewItem.MetadataBlob.place_of_use}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Tecnica</strong>: {{viewItem.MetadataBlob.technique}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>technique_0</strong>: {{viewItem.MetadataBlob.info.technique_0}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>technique_1</strong>: {{viewItem.MetadataBlob.info.technique_1}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>technique_2</strong>: {{viewItem.MetadataBlob.info.technique_2}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>technique_3</strong>: {{viewItem.MetadataBlob.info.technique_3}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Dominio</strong>: {{viewItem.MetadataBlob.info.domain}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Pittore</strong>: {{viewItem.MetadataBlob.info.painter}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Attribuito a</strong>: {{viewItem.MetadataBlob.info.attributed_to}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Person/Community</strong>: {{viewItem.MetadataBlob.info.person_community}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Titolo attribuito</strong>: {{viewItem.MetadataBlob.info.attributed_title}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Altro titolo attribuito</strong>: {{viewItem.MetadataBlob.info.other_attributed_title}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Soggetto</strong>: {{viewItem.MetadataBlob.info.subject}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Autore</strong>: {{viewItem.MetadataBlob.author}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Museo</strong>: {{viewItem.MetadataBlob.museum}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Descrizione</strong>: {{viewItem.MetadataBlob.description}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Altri testi</strong>:
              <pre>{{viewItem.MetadataBlob.text.join('\n\n')}}</pre>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Inscription</strong>:
              <pre>{{viewItem.MetadataBlob.inscription}}</pre>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Note</strong>:
              <pre>{{viewItem.MetadataBlob.notes}}</pre>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Opera ignorata</strong>: <span v-if="viewItem.ignored" class="error font-weight-bold">Sì</span><span v-else>No</span>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Url immagine</strong>: <a :href="viewItem.ImageURL" target="_blank">{{viewItem.ImageURL}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Sincronizzato il</strong>: {{viewItem.RetrievalDate}}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="accent" @click="viewDialog = false; viewItem = null">Chiudi</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
export default {
  data () {
    return {
      collectionId : null,
      refreshKey : 0,
      viewDialog : false,
      viewItem : null,
      setImagesDialog : false,
      images : {},
      columnsDialog: false,
      refreshTimeout : -1,
      filters : [],
      selectedItems : {},
      selectedProp : null,
      selectedColumns : {
        ThumbnailURL : true,
        Title : true,
        Artist : true,
        ObjectDate : true,
        'MetadataBlob.museum' : true,
        IsHighlight : true,
        IsPublicDomain : true,
      },
      hideIgnored : true,
      qSearch : null,
      collectionSearch : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : this.L('No. rows per page'),
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      columnOptions : [
        { text : 'Remote ID', value : 'RemoteID', type : 'string', sortable : true},
        { text : 'Collection', value : 'Collection', type : 'string', sortable : true},
        { text : 'Thumbnail', value : 'ThumbnailURL', type : 'string', sortable : true},
        { text : 'Image Name', value : 'ImageName', type : 'string', sortable : true},
        { text : 'Image Description', value : 'ImageDescription', type : 'string', sortable : true},
        { text : 'Titolo', value : 'Title', type : 'string', sortable : true},
        { text : 'Nome autore', value : 'Artist', type : 'string', sortable : false},
        { text : 'Datazione', value : 'ObjectDate', type : 'string', sortable : true},
        { text : 'Datazione 2', value : 'MetadataBlob.info.date', type : 'string', sortable : true},
        { text : 'cm hw', value : 'MetadataBlob.info.cm_hw', type : 'string', sortable : true},
        { text : 'cm hw with frame', value : 'MetadataBlob.info.cm_hw_with_frame', type : 'string', sortable : true},
        { text : 'cm hw no frame', value : 'MetadataBlob.info.cm_hw_no_frame', type : 'string', sortable : true},
        { text : 'Width', value : 'MetadataBlob.info.width', type : 'string', sortable : true},
        { text : 'Height', value : 'MetadataBlob.info.height', type : 'string', sortable : true},
        { text : 'Depth', value : 'MetadataBlob.info.depth', type : 'string', sortable : true},
        { text : 'Kg peso', value : 'MetadataBlob.info.kg_weight', type : 'string', sortable : true},
        { text : 'g peso', value : 'MetadataBlob.info.g_weight', type : 'string', sortable : true},
        { text : 'N. inventario', value : 'MetadataBlob.info.inv_number', type : 'string', sortable : true},
        { text : 'Event', value : 'MetadataBlob.info.event', type : 'string', sortable : true},
        { text : 'Luogo', value : 'MetadataBlob.info.place', type : 'string', sortable : true},
        { text : 'Materiale', value : 'MetadataBlob.info.material', type : 'string', sortable : true},
        { text : 'Parte n°', value : 'MetadataBlob.info.num_of_parts', type : 'string', sortable : true},
        { text : 'Creation stage', value : 'MetadataBlob.info.creation_stage', type : 'string', sortable : true},
        { text : 'Recipient', value : 'MetadataBlob.info.recipient', type : 'string', sortable : true},
        { text : 'Data di utilizzo', value : 'MetadataBlob.info.date_of_use', type : 'string', sortable : true},
        { text : 'Luogo di utilizzo', value : 'MetadataBlob.info.place_of_use', type : 'string', sortable : true},
        { text : 'Note', value : 'MetadataBlob.info.notes', type : 'string', sortable : true},
        { text : 'Inscription', value : 'MetadataBlob.info.inscription', type : 'string', sortable : true},
        { text : 'Museo', value : 'MetadataBlob.museum', type : 'string', sortable : true},
        { text : 'Tecnica', value : 'MetadataBlob.info.technique', type : 'string', sortable : false},
        { text : 'Tecnica 0', value : 'MetadataBlob.technique_0', type : 'string', sortable : false},
        { text : 'Tecnica 1', value : 'MetadataBlob.technique_1', type : 'string', sortable : false},
        { text : 'Tecnica 2', value : 'MetadataBlob.technique_2', type : 'string', sortable : false},
        { text : 'Tecnica 3', value : 'MetadataBlob.technique_3', type : 'string', sortable : false},
        { text : 'Tecnica 4', value : 'MetadataBlob.technique_4', type : 'string', sortable : false},
        { text : 'Tecnica 5', value : 'MetadataBlob.technique_5', type : 'string', sortable : false},
        { text : 'Tecnica 6', value : 'MetadataBlob.technique_6', type : 'string', sortable : false},
        { text : 'Museo', value : 'MetadataBlob.museum', type : 'string', sortable : false},
        { text : 'Dominio', value : 'MetadataBlob.info.domain', type : 'string', sortable : false},
        { text : 'Soggetto', value : 'MetadataBlob.info.subject', type : 'string', sortable : false},
        { text : 'Workshop', value : 'MetadataBlob.info.painter_workshop', type : 'string', sortable : false},
        { text : 'Attributed Title', value : 'MetadataBlob.info.attributed_title', type : 'string', sortable : false},
        { text : 'Other Attributed Title', value : 'MetadataBlob.info.other_attributed_title', type : 'string', sortable : false},
        { text : 'Attributed To', value : 'MetadataBlob.info.attributed_to', type : 'string', sortable : false},
        { text : 'Supporto', value : 'MetadataBlob.info.support', type : 'string', sortable : false},
        { text : 'Link Immagine', value : 'ImageURL', type : 'string', sortable : true},
       { text : 'Is Highlight', value : 'IsHighlight', type : 'bool', sortable : true},
        { text : 'Is Public Domain', value : 'IsPublicDomain', type : 'bool', sortable : true},
        { text : 'Ignora', value : 'ignore', type : 'bool', sortable : true},
        { text : 'ArtCentrica ID', value : 'artc_id', type : 'string', sortable : true},
        { text : 'ArtCentrica Imgf ID', value : 'artc_img_id', type : 'string', sortable : true},
        { text : 'ArtCentrica Tecnica ID', value : 'artc_tec_id', type : 'string', sortable : true},
        { text : 'ArtCentrica Room ID', value : 'artc_room_id', type : 'string', sortable : true},
        { text : 'Ultimo sync', value : 'RetrievalDate', type : 'string', sortable : false},
      ],
      count : 0,
      items : [],
      pagination: {
        sortBy : [ "Title" ],
        sortDesc : [ false ]
      },
      query : {
        hide_ignored : true,
      },
      boolOptions : [{text: 'Sì', value:true},{text: 'No', value:false}]
    }
  },
  watch: {
    pagination: {
      handler () {
        this.selectedItems = {}
        sessionStorage.reimsPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    },
  },
  computed : {
    ...mapGetters(['profile','collections','languageMap']),
    collectionOptions() {
      return this.collections.map(item => {
        let text = item.title.it|| item.title.en
        return { text, value : item._id }
      })
    },
    headers(){
      let cols = this.columnOptions.filter(item => this.selectedColumns[item.value]).map(item => {
        return { ...item, sortable: true, align: 'left'}
      })
      return [
        { text : "Selezione", sortable: false, align: 'center', value : 'select', width : 50 },
          ...cols,
        { text : "Vedi", sortable: false, align: 'center', value : 'view', width : 50 },
        { text : "Ignora", sortable: false, align: 'center', value : 'ignore', width : 50 },
      ]
    },
    server() {
      return config.server;
    },

  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'insert', 'get', 'delete','refreshMainCollection']),
    L( key ) {
      return this.languageMap && this.languageMap[key] ? this.languageMap[key] : key;
    },
    selectAll() {
      let pairs = _.toPairs(this.selectedItems);
      if(pairs.length < this.items.length) {
        for(let i = 0; i < this.items.length; i++) {
          this.selectedItems[this.items[i]._id] = true;
        }
      } else {
        this.selectedItems = {}
      }
      this.refreshKey++;
    },
    async enqueueSelected() {
      if(!this.collectionId) return;
      let pairs = _.toPairs(this.selectedItems);
      if(!pairs.length) return

      this.tableLoading = true
      let ids = [];
      for(let id in this.selectedItems) {
        ids.push(id)
      }

      try {
        let reply = await this.insert({collection : 'reims/enqueue', data : { collection_id : this.collectionId, ids }})
        if(reply.errors && reply.errors.length) {
          this.sendError({message:"Ci sono stati degli errori in alcune importazioni, vedi console del browser per maggiori dettagli"})
          console.log(['------------- ERRORI DI IMPORT --------------', ...reply.errors,'---------------------------------------'].join("\n"))
        } else {
          this.sendSuccess({message:"Record inseriti nella coda di importazione!"})
        }
        this.tableLoading = false
        this.refresh()
      } catch (e) {
        this.tableLoading = false
        this.sendError({message:"C'è stato un errore del server"})
        console.log(e)
      }

    },

    async toggleIgnore(item, noRefresh) {
      try {
        let id = item._id + ( item.ignore ? "/show" : "/hide" );
        let { data } = await this.get({collection:'reims', id})
        this.sendSuccess({message:"Operazione riuscita!"})
        if(!noRefresh) this.refresh()
        return data
      } catch (e) {
        this.sendError({message:"Errore del server: " + e.message})
      }
      return null
    },
    async ignoreSelected() {
      this.tableLoading = true
      for(let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if(this.selectedItems[item._id] && !item.ignore) {
          let ignored = await this.toggleIgnore(item)
          if (ignored) {
            this.items[i] = ignored;
            this.selectedItems[item._id] = false;
          }
        }
      }
      this.tableLoading = false
    },
    persistColumns() {
      localStorage.reimsColumns = JSON.stringify(this.selectedColumns);
    },
    editFilter(item) {
      let { text, value, type, filter} = item
      this.selectedProp = { text, value, type };
      this.qSearch = filter;
    },
    removeFilter(i) {
      this.filters.splice(i, 1)
      this.applyFilter();
    },
    setFilter() {
      if(!this.selectedProp && !this.qSearch) return
      let filter = {...this.selectedProp, filter: this.qSearch}

      for(let i = 0; i < this.filters.length; i++) {
        if(this.filters[i].value === filter.value) {
          this.filters[i] = filter
          this.applyFilter();
          return;
        }
      }
      this.filters.push(filter)
      this.applyFilter();
    },
    applyFilter() {
      this.selectedProp = null
      this.qSearch = null;
      this.query.filters = utils.copy(this.filters)
      this.pagination.page = 1;
      sessionStorage.reimsQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      if(sortBy && sortBy.length) {
        this.query.sort = sortBy[0]
        this.query.sort_direction = sortDesc && sortDesc[0] ? -1 : 1
      }

      this.query.limit = itemsPerPage;
      this.query.page = page - 1;

      this.insert({ collection : 'reims', data : this.query}).then((reply)=>{
        this.tableLoading = false;
        if(reply.success || reply.status === 'success') {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento del db reims."});
        if(config.debug) console.error("Errore al caricamento del db reims: ", err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.reimsQuery) {
      this.query = JSON.parse(sessionStorage.reimsQuery);
      this.filters = utils.copy(this.query.filters)
    }
    if(sessionStorage.reimsPagination) {
      this.pagination = JSON.parse(sessionStorage.reimsPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
    this.refreshMainCollection({collection:'collections'});
    if(localStorage.reimsColumns) {
      this.selectedColumns = JSON.parse(localStorage.reimsColumns)
    }

  }
}
</script>
