<template>
  <v-container class="pa-6">
    <v-row row>
      <v-col cols="12">
        <h2 class="display-1">{{L('Database MET')}}</h2>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <h3>{{L('Filters')}}:</h3>
        <v-chip class="mr-2" v-for="(filter, i) in filters" :key="`filter_${i}`" close @click="editFilter(filter)" @click:close="removeFilter(i)">{{filter.text}}: {{filter.filter}}</v-chip>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select outlined dense hide-details v-model="selectedProp" return-object :label="L('Column')" :items="columnOptions" @change="qSearch = '';"></v-select>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedProp">
        <v-select v-if="selectedProp.type === 'bool'" filled rounded dense hide-details v-model="qSearch" :label="L('Filter')" :items="boolOptions" ></v-select>
        <v-text-field v-else :type="selectedProp.type === 'int' ? 'number' : 'text'" :step="1" filled rounded dense hide-details v-model="qSearch" :label="L('Filter')"></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" v-if="selectedProp && qSearch !== null">
        <v-btn color="accent" @click="setFilter()">{{L('Set filter')}}</v-btn>
      </v-col>

      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="columnsDialog = true">Colonne da visualizzare</v-btn>
        <v-switch hide-details v-model="query.hide_ignored" :label="L('Hide ignored')" class="flex-grow-0 flex-shrink-1 mt-0 ml-3" @change="applyFilter"/>
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="selectAll()">{{L('Select all')}}</v-btn>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="ignoreSelected()">{{L('Ignore selected')}}</v-btn>
        <v-autocomplete outlined dense class="flex-grow-0 flex-shrink-1 ml-2" :label="L('Collection of import')" hide-details v-model="collectionId" :items="collectionOptions" clearable></v-autocomplete>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="generateSelected()" :disabled="!collectionId">{{L('Import selected')}}</v-btn>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                      :no-data-text="L('There are no records yet')" :footer-props="footerProps" >

          <template v-slot:item.select="{ item }">
            <v-checkbox v-model="selectedItems[item._id]" :key="item._id + 'check' + refreshKey"></v-checkbox>
          </template>
          <template v-slot:item.artwork_id="{ item }">
            <strong class="success--text" v-if="item.artwork_id">Sì</strong>
            <span v-else>No</span>
          </template>
          <template v-slot:item.thumb="{ item }">
            <v-img :src="getThumb(item)" contain class="grey" width="120" height="120"></v-img>
          </template>
          <template v-slot:item.images_url="{ item }">
            <a class="d-inline-flex ma-1" v-if="item.images_url" v-for="img in item.images_url" :key="img" :href="img">{{img}}</a>
          </template>

          <template v-slot:item.link_to="{ item }">
            <v-btn icon :href="item.link_resource" target="_blank" color="accent"><v-icon>mdi-web</v-icon></v-btn>
          </template>

          <template v-slot:item.set_images="{ item }">
            <v-btn icon @click="editImages(item)" color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>

          <template v-slot:item.view="{ item }">
            <v-btn icon @click="viewItem = item; viewDialog = true" color="accent"><v-icon>mdi-file-find</v-icon></v-btn>
          </template>

          <template v-slot:item.ignore="{ item }">
            <v-btn icon @click="toggleIgnore(item)"><v-icon v-if="item.ignore" color="error">mdi-eye-off</v-icon><v-icon v-else>mdi-eye-off</v-icon></v-btn>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="columnsDialog" max-width="800">
      <v-card>
        <v-card-title>
          {{L('Select the columns to show in the table')}}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="p in columnOptions" :key="p.value">
              <v-checkbox hide-details v-model="selectedColumns[p.value]" :label="p.text" @change="persistColumns()"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="accent" @click="columnsDialog = false">{{L('Close')}}</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="setImagesDialog" max-width="800">
      <v-card>
        <v-card-title><h4>{{L('Add images to record')}}</h4></v-card-title>
        <v-card-text v-if="viewItem">
          <v-row>
            <v-col cols="12" v-for="(num, i) in numImages" :key="`img_${i}`">
              <v-text-field hide-details outlined :label="`${L('Image')} ${num}`" v-model="images['img_' + i]" />
            </v-col>
            <v-col cols="12">
              <v-btn color="accent" @click="numImages++">{{L('Add another field')}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="images = {}; setImagesDialog = false" :loading="tableLoading">{{L('Cancel')}}</v-btn>
          <v-btn text @click="saveImages()" :loading="tableLoading">{{L('Save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="viewDialog" max-width="800">
      <v-card>
        <v-card-text v-if="viewItem">
          <v-row class="pt-6">
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object ID</strong>: {{viewItem.object_id}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object Number</strong>: {{viewItem.object_number}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Gallery Number</strong>: {{viewItem.gallery_number}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Is Highlight</strong>: {{viewItem.is_highlight}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Is Timeline Work</strong>: {{viewItem.is_timeline_work}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Is Public Domain</strong>: {{viewItem.is_public_domain}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Accession Year</strong>: {{viewItem.accession_year}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object Name</strong>: {{viewItem.object_name}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Title</strong>: {{viewItem.title}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Culture</strong>: {{viewItem.culture}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Period</strong>: {{viewItem.period}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Dynasty</strong>: {{viewItem.dynasty}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Reign</strong>: {{viewItem.reign}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Portfolio</strong>: {{viewItem.portfolio}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Constiuent ID</strong>: {{viewItem.constiuent_id}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Role</strong>: {{viewItem.artist_role}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Prefix</strong>: {{viewItem.artist_prefix}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Display Name</strong>: {{viewItem.artist_display_name}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Display Bio</strong>: {{viewItem.artist_display_bio}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Suffix</strong>: {{viewItem.artist_suffix}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Alpha Sort</strong>: {{viewItem.artist_alpha_Sort}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Nationality</strong>: {{viewItem.artist_nationality}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Begin Date</strong>: {{viewItem.artist_begin_date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist End Date</strong>: {{viewItem.artist_end_date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Gender</strong>: {{viewItem.artist_gender}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist ULAN URL</strong>: <a :href="viewItem.artist_ulan_url" target="_blank">{{viewItem.artist_ulan_url}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Wikidata URL</strong>: <a :href="viewItem.artist_wikidata_url" target="_blank">{{viewItem.artist_wikidata_url}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Artist Role</strong>: {{viewItem.artist_role}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object Date</strong>: {{viewItem.object_date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object Begin Date</strong>: {{viewItem.object_begin_date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object End Date</strong>: {{viewItem.object_end_date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Medium</strong>: {{viewItem.medium}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Dimensions</strong>: {{viewItem.dimensions}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Credit Line</strong>: {{viewItem.credit_line}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Geography Type</strong>: {{viewItem.geography_type}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>City</strong>: {{viewItem.city}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>State</strong>: {{viewItem.state}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>County</strong>: {{viewItem.county}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Region</strong>: {{viewItem.region}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Subregion</strong>: {{viewItem.subregion}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Locale</strong>: {{viewItem.locale}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Locus</strong>: {{viewItem.locus}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Excavation</strong>: {{viewItem.excavation}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>River</strong>: {{viewItem.river}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Classification</strong>: {{viewItem.classification}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Rights and Reproduction</strong>: {{viewItem.rights_and_reproduction}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Link Resource</strong>:<a :href="viewItem.link_resource" target="_blank">{{viewItem.link_resource}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Object Wikidata URL</strong>:<a :href="viewItem.object_wikidata_url" target="_blank">{{viewItem.object_wikidata_url}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Metadata Date</strong>: {{viewItem.metadata_date}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Repository</strong>: {{viewItem.repository}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Tags</strong>: {{viewItem.tags}}
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Tags AAT URL</strong>:<a :href="viewItem.tags_aat_url" target="_blank">{{viewItem.tags_aat_url}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Tags Wikidata URL</strong>:<a :href="viewItem.tags_wikidata_url" target="_blank">{{viewItem.tags_wikidata_url}}</a>
            </v-col>
            <v-col cols="12" class="d-flex align-center pb-0" v-if="viewItem.images_url">
              <v-icon small>mdi-chevron-right</v-icon>
              <strong>Images</strong>:
              <a v-for="img in viewItem.images_url" :key="img" class="ml-2" :href="img" target="_blank">{{img}}</a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="accent" @click="viewDialog = false; viewItem = null">Chiudi</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
export default {
  data () {
    return {
      collectionId : null,
      refreshKey : 0,
      viewDialog : false,
      viewItem : null,
      setImagesDialog : false,
      numImages : 3,
      images : {},
      columnsDialog: false,
      refreshTimeout : -1,
      filters : [],
      selectedItems : {},
      selectedProp : null,
      selectedColumns : {
        object_number : true,
        is_highlight : true,
        is_public_domain : true,
        object_name : true,
        title : true,
        artist_display_name : true,
        artist_alpha_sort : true,
        rights_and_reproduction : true,
      },
      hideIgnored : true,
      qSearch : null,
      collectionSearch : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : this.L('No. rows per page'),
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      columnOptions : config.metProps,
      count : 0,
      items : [],
      pagination: {
        sortBy : [ "object_id" ],
        sortDesc : [ false ]
      },
      query : {
        hide_ignored : true,
      },
      boolOptions : [{text: 'Sì', value:true},{text: 'No', value:false}]
    }
  },
  watch: {
    pagination: {
      handler () {
        this.selectedItems = {}
        sessionStorage.metPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    },
  },
  computed : {
    ...mapGetters(['profile','collections','languageMap']),
    collectionOptions() {
      return this.collections.map(item => {
        let text = item.title.it|| item.title.en
        return { text, value : item._id }
      })
    },
    headers(){
      let cols = this.columnOptions.filter(item => this.selectedColumns[item.value]).map(item => {
        return { ...item, sortable: true, align: 'left'}
      })
      return [
        { text : this.L('Selection'), sortable: false, align: 'center', value : 'select', width : 50 },
        { text : this.L('Imported'), sortable: false, align: 'center', value : 'artwork_id', width : 60 },
        { text : this.L('Thumb'), sortable: false, align: 'center', value : 'thumb', width : 120 },
          ...cols,
        { text : this.L('View images'), sortable: false, align: 'center', value : 'link_to', width : 50 },
        { text : this.L('Set images'), sortable: false, align: 'center', value : 'set_images', width : 50 },
        { text : this.L('View'), sortable: false, align: 'center', value : 'view', width : 50 },
        { text : this.L('Ignore'), sortable: false, align: 'center', value : 'ignore', width : 50 },
      ]
    },
    server() {
      return config.server;
    },

  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'insert', 'get', 'delete','refreshMainCollection']),
    L( key ) {
      return this.languageMap && this.languageMap[key] ? this.languageMap[key] : key;
    },
    getThumb(item) {
      if(item.images_url && item.images_url.length) {
        return item.images_url[0].replace(/original/, "mobile-large")
      }
      return null
    },
    selectAll() {
      let pairs = _.toPairs(this.selectedItems);
      if(pairs.length < this.items.length) {
        for(let i = 0; i < this.items.length; i++) {
          this.selectedItems[this.items[i]._id] = true;
        }
      } else {
        this.selectedItems = {}
      }
      this.refreshKey++;
    },
    async generateSelected() {
      if(!this.collectionId) return;
      let pairs = _.toPairs(this.selectedItems);
      if(!pairs.length) return

      this.tableLoading = true
      let ids = [];
      for(let id in this.selectedItems) {
        ids.push(id)
      }

      try {
        let reply = await this.insert({collection : 'met/generate', data : { collection_id : this.collectionId, ids }})
        if(reply.errors && reply.errors.length) {
          this.sendError({message:"Ci sono stati degli errori in alcune importazioni, vedi console del browser per maggiori dettagli"})
          console.log(['------------- ERRORI DI IMPORT --------------', ...reply.errors,'---------------------------------------'].join("\n"))
        } else {
          this.sendSuccess({message:"Record importati correttamente!"})
        }
        this.tableLoading = false
        this.refresh()
      } catch (e) {
        this.tableLoading = false
        this.sendError({message:"C'è stato un errore del server"})
        console.log(e)
      }

    },
    editImages(item) {
      if(item.images_url && item.images_url.length) {
        for(let i = 0; i < item.images_url.length; i++) {
          this.images['img_' + i] = item.images_url[i];
          console.log("set img_"+i)
        }
        this.numImages = Math.max(item.images_url.length + 1, 3)
      } else {
        this.numImages = 3;
      }
      this.viewItem = item;
      this.setImagesDialog = true
    },
    async saveImages() {
      if(!this.viewItem) return
      this.tableLoading = true;
      let urls = []
      for(let i = 0; i < this.numImages; i++) {
        if(this.images['img_' + i]) {
          urls.push(this.images['img_' + i]);
        }
      }
      try {
        await this.insert({collection:`met/${this.viewItem._id}/images`, data : { urls } })
        this.sendSuccess({message:"Operazione riuscita!"})
        this.refresh()
        this.viewItem = null
        this.images = {};
        this.setImagesDialog = false
      } catch (e) {
        this.sendError({message:"Errore del server: " + e.message})
        this.tableLoading = false;
      }
    },
    async toggleIgnore(item, noRefresh) {
      try {
        let id = item._id + ( item.ignore ? "/show" : "/hide" );
        let { data } = await this.get({collection:'met', id})
        this.sendSuccess({message:"Operazione riuscita!"})
        if(!noRefresh) this.refresh()
        return data
      } catch (e) {
        this.sendError({message:"Errore del server: " + e.message})
      }
      return null
    },
    async ignoreSelected() {
      this.tableLoading = true
      for(let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if(this.selectedItems[item._id] && !item.ignore) {
          let ignored = await this.toggleIgnore(item)
          if (ignored) {
            this.items[i] = ignored;
            this.selectedItems[item._id] = false;
          }
        }
      }
      this.tableLoading = false
    },
    persistColumns() {
      localStorage.metColumns = JSON.stringify(this.selectedColumns);
    },
    editFilter(item) {
      let { text, value, type, filter} = item
      this.selectedProp = { text, value, type };
      this.qSearch = filter;
    },
    removeFilter(i) {
      this.filters.splice(i, 1)
      this.applyFilter();
    },
    setFilter() {
      if(!this.selectedProp && !this.qSearch) return
      let filter = {...this.selectedProp, filter: this.qSearch}

      for(let i = 0; i < this.filters.length; i++) {
        if(this.filters[i].value === filter.value) {
          this.filters[i] = filter
          this.applyFilter();
          return;
        }
      }
      this.filters.push(filter)
      this.applyFilter();
    },
    applyFilter() {
      this.selectedProp = null
      this.qSearch = null;
      this.query.filters = utils.copy(this.filters)
      this.pagination.page = 1;
      sessionStorage.metQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      if(sortBy && sortBy.length) {
        this.query.sort = sortBy[0]
        this.query.sort_direction = sortDesc && sortDesc[0] ? -1 : 1
      }

      this.query.limit = itemsPerPage;
      this.query.page = page - 1;

      this.insert({ collection : 'met', data : this.query}).then((reply)=>{
        this.tableLoading = false;
        if(reply.success || reply.status === 'success') {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento del db met."});
        if(config.debug) console.error("Errore al caricamento del db met: ", err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.metQuery) {
      this.query = JSON.parse(sessionStorage.metQuery);
      this.filters = utils.copy(this.query.filters)
    }
    if(sessionStorage.metPagination) {
      this.pagination = JSON.parse(sessionStorage.metPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
    this.refreshMainCollection({collection:'collections'});
    if(localStorage.metColumns) {
      this.selectedColumns = JSON.parse(localStorage.metColumns)
    }

  }
}
</script>
